import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LogoutPage: React.FC = () => {
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const redirectUrl = queryParams.get("redirectUrl");
  const userId = queryParams.get("userId");

  useEffect(() => {
    if (userId) {
      localStorage.removeItem(`${userId}-token`);
    } else {
      localStorage.clear();
    }

    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      navigate("/");
    }
  }, [navigate, redirectUrl, userId]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div>
        <div className="loader"></div>
        <p>Logging out. Please wait...</p>
      </div>
    </div>
  );
};

export default LogoutPage;
