import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Input, Button, Typography, Alert, Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import PageFooter from "../components/pageFooter";
import Header from "../components/header";
import "./styles.scss";
import md5 from "md5";

const AdminSignUpPage: React.FC = () => {
  const { campId } = useParams<{ campId: string }>();
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const handleSubmit = async (values: any) => {
    if (values.password !== values.confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    let payload = {
      campId,
      email: values.email,
      fullName: values.fullName,
      password: md5(values.password),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_WORKCAMP_API_URL}/api/user/signup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const result = await response.json();

      if (response.ok && result.success) {
        navigate({
          pathname: `/verification/${result.user.email}`,
          search: new URLSearchParams(window.location.search).toString(),
        });
      } else {
        setError(result.message || "Signup failed. Try again.");
      }
    } catch (error) {
      setError("Network error. Try again.");
    }
  };

  return (
    <Layout className="admin-signup">
      <Header showExtras={true} />

      <Content className="content">
        <Typography.Title className="heading">Admin Signup</Typography.Title>
        <Typography.Paragraph className="subheading">
          We need some basic information about you.
        </Typography.Paragraph>
        {error && <Alert message={error} type="error" showIcon />}
        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name="fullName"
            label="Full Name"
            className="form"
            rules={[
              { required: true, message: "Please input your full name!" },
            ]}
          >
            <Input type="text" placeholder="Full Name" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email Address"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input type="email" placeholder="Email" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item
            label="Retype Password"
            name="confirmPassword"
            rules={[
              { required: true, message: "Please confirm your password!" },
            ]}
          >
            <Input.Password placeholder="Retype Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Sign Up as Admin
            </Button>
          </Form.Item>
        </Form>
      </Content>
      <PageFooter />
    </Layout>
  );
};

export default AdminSignUpPage;
