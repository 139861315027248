import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Typography, Layout, Alert } from "antd";

import Header from "../components/header";
import { Content } from "antd/es/layout/layout";
import PageFooter from "../components/pageFooter";
import "./styles.scss";

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleForgotPassword = async () => {
    const payload = {
      email,
    };

    const response = await fetch(
      `${process.env.REACT_APP_WORKCAMP_API_URL}/api/user/forgot-password`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    );
    const data = await response.json();
    if (response.status === 409) {
      setError("Email does not exist");
    } else if (data.success) {
      setError("");
      navigate({
        pathname: `/verification/${email}`,
        search: new URLSearchParams(window.location.search).toString(),
      }); // Redirect to OTP verification page
    } else {
      setError(data.error || "Forgot password request failed. Try again.");
    }
  };

  return (
    <Layout className="signin">
      <Header showExtras={true} />

      <Content className="content">
        <Typography.Title className="heading">Forgot Password</Typography.Title>
        <Typography.Paragraph className="subheading">
          Enter your email address to start the process
        </Typography.Paragraph>
        {error && <Alert message={error} type="error" showIcon />}
        <Form
          layout="vertical"
          name="sign_in"
          className="form"
          initialValues={{ remember: true }}
          onFinish={handleForgotPassword}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your Email!" }]}
            label="Email Address"
          >
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="link"
              onClick={() =>
                navigate({
                  pathname: "/camp",
                  search: new URLSearchParams(
                    window.location.search
                  ).toString(),
                })
              }
            >
              Back to Camp Page
            </Button>
          </Form.Item>
        </Form>
      </Content>
      <PageFooter />
    </Layout>
  );
};

export default ForgotPasswordPage;
