import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Input, Button, Typography, Alert, Layout, Select } from "antd";
import Header from "../components/header";
import { Content } from "antd/es/layout/layout";
import PageFooter from "../components/pageFooter";

const CampRegistrationPage: React.FC = () => {
  const { campName } = useParams<{ campName: string }>();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    organization: "",
    industry: "",
    website: "",
  });
  const [error, setError] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WORKCAMP_API_URL}/api/camp/`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            campName: campName,
            orgName: formData.organization,
            industry: formData.industry,
            websiteLink: formData.website,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        navigate({
          pathname: `/camp/${data.camp.id}/admin-signup`,
          search: new URLSearchParams(window.location.search).toString(),
        });
      } else {
        const errorData = await response.json();
        if (response.status === 409) {
          setError(errorData.error || "Registration failed. Try again.");
        } else {
          setError("Registration failed. Try again.");
        }
      }
    } catch (error) {
      setError("Network error. Try again.");
    }
  };

  return (
    <Layout className="registration">
      <Header showExtras={true} />
      <Content className="content">
        <Typography.Title className="heading">
          Camp Registration ({campName})
        </Typography.Title>
        <Typography.Paragraph className="subheading">
          Let's get to know about your organization.
        </Typography.Paragraph>
        {error && <Alert message={error} type="error" showIcon />}
        <Form className="form" layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label="Organization Name"
            name="organization"
            rules={[
              {
                required: true,
                message: "Please input your organization name!",
              },
            ]}
          >
            <Input
              name="organization"
              placeholder="Organization Name"
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item
            label="Industry"
            name="industry"
            rules={[
              { required: true, message: "Please select your industry!" },
            ]}
          >
            <Select
              placeholder="Select Industry"
              onChange={(value) =>
                setFormData({ ...formData, industry: value })
              }
            >
              <Select.Option value="technology">Technology</Select.Option>
              <Select.Option value="finance">Finance</Select.Option>
              <Select.Option value="healthcare">Healthcare</Select.Option>
              <Select.Option value="education">Education</Select.Option>
              <Select.Option value="other">Other</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Organization Website"
            name="website"
            rules={[
              {
                required: true,
                message: "Please input your organization website!",
              },
            ]}
          >
            <Input
              addonBefore="https://"
              name="website"
              placeholder="Organization Website"
              onChange={(e) => {
                const value = e.target.value.startsWith("https://")
                  ? e.target.value
                  : `https://${e.target.value}`;
                setFormData({ ...formData, website: value });
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Register Camp
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="link"
              onClick={() =>
                navigate({
                  pathname: "/camp",
                  search: new URLSearchParams(
                    window.location.search
                  ).toString(),
                })
              }
            >
              Already have a camp? Go to Camp Page
            </Button>
          </Form.Item>
        </Form>
      </Content>
      <PageFooter />
    </Layout>
  );
};

export default CampRegistrationPage;
