import React from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "@ant-design/pro-layout";
import { Button } from "antd";

interface HeaderProps {
  showExtras?: boolean;
}

const Header: React.FC<HeaderProps> = ({ showExtras = true }) => {
  const navigate = useNavigate();

  const goToCamp = () => {
    navigate({
      pathname: "/camp",
      search: new URLSearchParams(window.location.search).toString(),
    });
  };

  const [isMenuCollapsed, setIsMenuCollapsed] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMenuCollapsed(true);
      } else {
        setIsMenuCollapsed(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <PageHeader
      className="header"
      title={
        <img
          className="logo"
          src="/Workcamp-Logo.png"
          alt="Workcamp"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        />
      }
      extra={
        showExtras && !isMenuCollapsed
          ? [
              <Button
                className="button"
                key="3"
                onClick={() => navigate("/terms")}
              >
                Terms
              </Button>,
              <Button
                className="button"
                key="2"
                onClick={() => navigate("/privacy")}
              >
                Privacy
              </Button>,
              <Button
                className="button"
                key="1"
                type="primary"
                onClick={goToCamp}
              >
                Go to Camp
              </Button>,
            ]
          : null
      }
    />
  );
};

export default Header;
