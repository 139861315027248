import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Input, Button, Typography, Alert, Layout } from "antd";
import Header from "../components/header";
import { Content } from "antd/es/layout/layout";
import PageFooter from "../components/pageFooter";
import "./styles.scss";

const VerificationPage: React.FC = () => {
  const { email } = useParams<{ email: string }>();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isResendDisabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            setIsResendDisabled(false);
            return 60;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isResendDisabled]);

  const resendOtp = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WORKCAMP_API_URL}/api/user/resend-otp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, flag: "RESET_PASSWORD" }),
        }
      );

      const result = await response.json();
      if (!response.ok) {
        setError(
          result.error || result.message || "Failed to resend OTP. Try again."
        );
      } else if (result.success) {
        setMessage(result.message || "OTP sent successfully.");
        setIsResendDisabled(true);
      }
    } catch (error) {
      setError("Network error. Try again.");
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WORKCAMP_API_URL}/api/user/verify-otp`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email, otp, flag: "RESET_PASSWORD" }),
        }
      );

      const result = await response.json();
      if (response.ok && result.success) {
        if (result.flag === "RESET_PASSWORD") {
          navigate({
            pathname: `/new-password/${result.token}`,
            search: new URLSearchParams(window.location.search).toString(),
          });
        } else if (result.flag === "SIGNUP" || result.flag === "ADMIN_SIGNUP") {
          navigate({
            pathname: `/signin/${result.camp.campName}/`,
            search: new URLSearchParams(window.location.search).toString(),
          });
        } else {
          navigate({
            pathname: `/camp`,
            search: new URLSearchParams(window.location.search).toString(),
          });
        }
      } else {
        setError(result.error || result.message || "Invalid OTP. Try again.");
      }
    } catch (error) {
      setError("Network error. Try again.");
    }
  };

  return (
    <Layout className="verification">
      <Header showExtras={true} />

      <Content className="content">
        <Typography.Title className="heading">
          OTP Verification
        </Typography.Title>
        <Typography.Paragraph className="subheading">
          {`Please enter the code that has been received on ${email}`}
        </Typography.Paragraph>

        {error && <Alert message={error} type="error" showIcon />}
        {message && <Alert message={message} type="success" showIcon />}
        <Form onFinish={handleSubmit}>
          <Form.Item
            name="otp"
            className="form"
            rules={[{ required: true, message: "Please enter your OTP!" }]}
          >
            <Input
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Verify
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="link"
              onClick={resendOtp}
              block
              disabled={isResendDisabled}
            >
              {isResendDisabled ? `Resend OTP (${timer}s)` : "Resend OTP"}
            </Button>
          </Form.Item>
        </Form>
      </Content>
      <PageFooter />
    </Layout>
  );
};

export default VerificationPage;
