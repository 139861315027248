import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Input, Button, Typography, Layout, Alert } from "antd";
import md5 from "md5";
import Header from "../components/header";
import { Content } from "antd/es/layout/layout";
import PageFooter from "../components/pageFooter";
import "./styles.scss";

const SignUpPage = () => {
  const { campName } = useParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [campId, setCampId] = useState("0");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCampDetails = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_WORKCAMP_API_URL}/api/camp/${campName}`
      );
      const data = await response.json();
      if (data.success) {
        setCampId(data.camp.id);
      } else {
        setError(`Camp name ${campName} was not found.`);
        setTimeout(() => {
          navigate("/camp");
        }, 2000);
      }
    };

    fetchCampDetails();
  }, [campName, navigate]);

  const handleSignUp = async (values: any) => {
    let payload: any = {
      campId,
      email: values.email,
      fullName: values.fullName,
      password: md5(values.password),
    };

    const response = await fetch(
      `${process.env.REACT_APP_WORKCAMP_API_URL}/api/user/signup`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    );
    const data = await response.json();
    if (response.ok) {
      navigate({
        pathname: `/verification/${values.email}`,
        search: new URLSearchParams(window.location.search).toString(),
      });
    } else {
      setError(data.error || "An error occurred. Please try again.");
    }
  };

  return (
    <Layout className="signup">
      <Header />

      <Content className="content">
        <Typography.Title className="heading">
          Sign up ({campName})
        </Typography.Title>
        <Typography.Paragraph className="subheading">
          We need some basic information about you.
        </Typography.Paragraph>
        {error && <Alert message={error} type="error" showIcon />}
        <Form
          layout="vertical"
          name="sign_up"
          className="form"
          initialValues={{ remember: true }}
          onFinish={handleSignUp}
        >
          <Form.Item
            name="fullName"
            rules={[
              { required: true, message: "Please input your Full Name!" },
            ]}
            label="Full Name"
          >
            <Input placeholder="Full Name" />
          </Form.Item>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[{ required: true, message: "Please input your Email!" }]}
          >
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}
          >
            <Input.Password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item
            label="Retype Password"
            name="retypePassword"
            dependencies={["password"]}
            rules={[
              { required: true, message: "Please retype your Password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Retype Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Sign Up
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="link" onClick={() => navigate(`/signin/${campName}`)}>
              Already have an account, Go to Sign In
            </Button>
          </Form.Item>
        </Form>
      </Content>
      <PageFooter />
    </Layout>
  );
};

export default SignUpPage;
