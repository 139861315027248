import React from "react";
import { Layout, Button, Card } from "antd";
import { Typography } from "antd";
import { useNavigate } from "react-router-dom";

import "./styles.scss";
import Header from "../components/header";
import PageFooter from "../components/pageFooter";
import { loggedInUser } from "../common/interfaces";

const { Content } = Layout;

const MainPage: React.FC = () => {
  const navigate = useNavigate();
  const [loggedInUsers, setLoggedInUsers] = React.useState<loggedInUser[]>([]);

  const goToCamp = () => {
    const searchParams = new URLSearchParams(window.location.search);
    navigate({
      pathname: "/camp",
      search: searchParams.toString(),
    });
  };

  React.useEffect(() => {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.endsWith("-token")) {
        const token = localStorage.getItem(key);
        if (token) {
          try {
            const decodedToken = JSON.parse(atob(token.split(".")[1]));
            const user: loggedInUser = {
              _id: decodedToken._id,
              fullName: decodedToken.fullName,
              email: decodedToken.email,
              verified: decodedToken.verified,
              role: decodedToken.role,
              roleTypeId: decodedToken.roleTypeId,
              campId: decodedToken.campId,
              userCampId: decodedToken.userCampId,
              loginType: decodedToken.loginType,
              iat: decodedToken.iat,
              token: token,
            };
            setLoggedInUsers((prevUsers) => [...prevUsers, user]);
          } catch (error) {
            console.error("Failed to decode token:", error);
          }
        }
      }
    }
  }, []);

  return (
    <Layout className="home">
      <Header showExtras={true} />

      <Content className="content">
        <Typography.Title className="heading">
          Simplified Collaborations!
        </Typography.Title>
        <Typography.Paragraph className="subheading">
          Our solution helps you simplify the collaboration by bringing
          everything on-to a single platform.
        </Typography.Paragraph>
        {loggedInUsers.length > 0 && (
          <Typography.Title level={4}>
            Continue an existing session
          </Typography.Title>
        )}
        {loggedInUsers.length > 0 && (
          <Layout className="logged-in-account">
            {loggedInUsers.map((user) => (
              <Card
                key={user._id}
                title={user.fullName}
                className="user-card"
                onClick={() => {
                  const searchParams = new URLSearchParams(
                    window.location.search
                  );
                  const redirectUrl =
                    searchParams.get("redirectUrl") ||
                    process.env.REACT_APP_WORKCAMP_HELLO_URL;
                  window.location.href = `${redirectUrl}?token=${user.token}`;
                }}
                hoverable
                extra={
                  <Button
                    type="text"
                    className="close-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        window.confirm(
                          "Are you sure you want to logout from this saved account?"
                        )
                      ) {
                        window.location.href = `${process.env.REACT_APP_WORKCAMP_HELLO_URL}/logout?userId=${user.userCampId}`;
                        // localStorage.removeItem(`${user.userCampId}-token`);
                        // setLoggedInUsers((prevUsers) =>
                        //   prevUsers.filter((u) => u._id !== user._id)
                        // );
                        // window.location.reload();
                      }
                    }}
                  >
                    ✖
                  </Button>
                }
              >
                <p>{user.email}</p>
              </Card>
            ))}
          </Layout>
        )}
        <Button type="primary" onClick={goToCamp}>
          Go to Camp
        </Button>
        <div className="floating-images">
          <img className="img00" src="/fl-00.png" alt="Floating 1" />
          <img className="img01" src="/fl-01.png" alt="Floating 2" />
          <img className="img02" src="/fl-02.png" alt="Floating 3" />
        </div>
      </Content>
      <PageFooter />
    </Layout>
  );
};

export default MainPage;
