import React from "react";
import { Typography, Layout } from "antd";
import Header from "../components/header";
import PageFooter from "../components/pageFooter";

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const Terms: React.FC = () => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header />
      <Content style={{ padding: "50px" }}>
        <Typography>
          <Title>Terms and Conditions</Title>
          <Title level={4}>Acceptance of Terms</Title>
          <Paragraph>
            By accessing or using Workcamp, you agree to comply with and be
            bound by these Terms and Conditions. If you do not agree to these
            terms, please do not use the Product.
          </Paragraph>
          <Title level={4}>Use of the Product</Title>
          <Paragraph>
            PROHIBITED ACTIVITIES: You agree not to engage in any of the
            following prohibited activities: <br />
            CONTENT: You are responsible for the content you create, share, or
            upload using the Product. You agree not to infringe upon the
            intellectual property rights of others. <br />
            A. Violating any applicable laws or regulations. <br />
            B. Transmitting harmful code, viruses, or malicious software. <br />
            C. Violating the privacy of others. D. Impersonating others or
            providing false information.
          </Paragraph>
          <Title level={4}>Privacy</Title>
          <Paragraph>
            DATA COLLECTION: We may collect and use information as described in
            our Privacy Policy.
          </Paragraph>
          <Title level={4}>Termination</Title>
          <Paragraph>
            We reserve the right to terminate or suspend your account and access
            to the Product at our discretion, without notice, for any violation
            of these terms.
          </Paragraph>
          <Title level={4}>Intellectual Property</Title>
          <Paragraph>
            The Product and its content, including but not limited to text,
            graphics, logos, and software, are protected by intellectual
            property laws. You may not reproduce, distribute, or modify any part
            of the Product without our prior written consent.
          </Paragraph>
          <Title level={4}>Changes to Terms</Title>
          <Paragraph>
            We reserve the right to modify these Terms and Conditions at any
            time. Updated terms will be posted on the Product, and your
            continued use constitutes acceptance of the updated terms.
          </Paragraph>
          <Title level={4}>Your Choices</Title>
          <Paragraph>
            You can manage your account and communication preferences within the
            Product. You may also contact us to exercise your rights under
            applicable data protection laws.
          </Paragraph>
          <Title level={4}>Account Deletion</Title>
          <Paragraph>
            A. Users may request the deletion of their account at any time.
            <br />
            B. Upon account deletion, all user data, including personal
            information, messages, and files, will be permanently removed from
            the WorkCamp platform. <br />
            C. Deleted accounts cannot be restored, and users are responsible
            for backing up any data they wish to retain before requesting
            deletion.
          </Paragraph>
          <Title level={4}>Account Deletion Process</Title>
          <Paragraph>
            We use the following steps to delete the user account API ENDPOINT:
            https://api.workcamp.app/api/user METHOD: DELETE HEADER: jwt_token
          </Paragraph>
          <Title level={4}>File Deletion Process</Title>
          <Paragraph>
            We use the following steps to delete the user files API ENDPOINT:
            https://api.workcamp.app/chat-management/attachments?attachmentI=attachment_id
            METHOD: DELETE HEADER: jwt_token
          </Paragraph>
          <Title level={4}>Contact Us</Title>
          <Paragraph>
            If you have any questions about these Terms and Conditions, please
            contact us at info@workcamp.com
          </Paragraph>
        </Typography>
      </Content>
      <PageFooter />
    </Layout>
  );
};

export default Terms;
