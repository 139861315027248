import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Input, Button, Typography, Radio, Layout, Alert } from "antd";
import md5 from "md5";
import { loginType } from "../common/interfaces";
import Header from "../components/header";
import { Content } from "antd/es/layout/layout";
import PageFooter from "../components/pageFooter";
import "./styles.scss";

const SignInPage = () => {
  const { campName } = useParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [campId, setCampId] = useState("0");
  const [loginOptions, setLoginOptions] = useState<loginType[]>([
    { _id: "000", title: "Standard", enable: true },
  ]);
  const [selectedLoginOption, setSelectedLoginOption] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCampDetails = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_WORKCAMP_API_URL}/api/camp/${campName}`
      );
      const data = await response.json();
      if (data.success) {
        setCampId(data.camp.id);
        setLoginOptions((prevOptions) => [
          ...prevOptions,
          ...data.camp.loginOptions,
        ]);
      } else {
        setError(`Camp name ${campName} was not found.`);
        setTimeout(() => {
          navigate("/camp");
        }, 2000);
      }
    };

    fetchCampDetails();
  }, [campName, navigate]);

  const handleSignIn = async () => {
    let payload: any = {
      email,
      campId,
      password: md5(password),
    };

    if (selectedLoginOption && selectedLoginOption !== "000") {
      payload["loginType"] = "LDAP";
      payload["ldapId"] = selectedLoginOption;
      payload["password"] = password;
    }

    const response = await fetch(
      `${process.env.REACT_APP_WORKCAMP_API_URL}/api/user/signin`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    );
    const data = await response.json();
    if (data.success) {
      const decodedToken = JSON.parse(atob(data.token.split(".")[1]));
      const userCampId = decodedToken.userCampId;
      localStorage.setItem(`${userCampId}-token`, data.token);
      const searchParams = new URLSearchParams(window.location.search);
      const redirectUrl =
        searchParams.get("redirectUrl") ||
        process.env.REACT_APP_WORKCAMP_HELLO_URL;
      window.location.href = `${redirectUrl}?token=${data.token}`;
    } else {
      setError(data.error || "Sign in failed. Try again.");
      setPassword("");
    }
  };

  return (
    <Layout className="signin">
      <Header showExtras={true} />

      <Content className="content">
        <Typography.Title className="heading">
          Sign in ({campName})
        </Typography.Title>
        <Typography.Paragraph className="subheading">
          Enter your credentials here
        </Typography.Paragraph>
        {error && <Alert message={error} type="error" showIcon />}
        <Form
          layout="vertical"
          name="sign_in"
          className="form"
          initialValues={{ remember: true }}
          onFinish={handleSignIn}
        >
          {loginOptions.length > 1 && [
            <Form.Item name="loginOption" label="Login Options" required>
              <Radio.Group
                onChange={(e) => {
                  setError("");
                  setSelectedLoginOption(e.target.value);
                }}
                value={selectedLoginOption}
              >
                {loginOptions.map((loginOp) => (
                  <Radio key={loginOp._id} value={loginOp._id}>
                    {loginOp.title}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>,
          ]}
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your Email!" }]}
            label="Email Address"
          >
            <Input
              type="email"
              value={email}
              onChange={(e) => {
                setError("");
                setEmail(e.target.value);
              }}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}
            label="Password"
          >
            <Input.Password
              value={password}
              onChange={(e) => {
                setError("");
                setPassword(e.target.value);
              }}
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={!!error}>
              Sign In
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="link" onClick={() => navigate("/forgot")}>
              Forgot Password?
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="link"
              onClick={() =>
                navigate({
                  pathname: `/signup/${campName}`,
                  search: new URLSearchParams(
                    window.location.search
                  ).toString(),
                })
              }
            >
              Don't have account, Go to Sign up
            </Button>
            <Button
              type="link"
              onClick={() =>
                navigate({
                  pathname: "/camp",
                  search: new URLSearchParams(
                    window.location.search
                  ).toString(),
                })
              }
            >
              Back to Camp Page
            </Button>
          </Form.Item>
        </Form>
      </Content>
      <PageFooter />
    </Layout>
  );
};

export default SignInPage;
