import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Input, Button, Typography, Layout } from "antd";
import md5 from "md5";
import Header from "../components/header";
import { Content } from "antd/es/layout/layout";
import PageFooter from "../components/pageFooter";
import "./styles.scss";

const NewPasswordPage = () => {
  const { token, flag } = useParams();
  const [password, setPassword] = useState("");
  const [userToken, setUserToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!token && !flag) {
      navigate({
        pathname: `/camp`,
        search: new URLSearchParams(window.location.search).toString(),
      });
    }

    if (flag) {
      fetch(
        `${process.env.REACT_APP_WORKCAMP_API_URL}/api/user/${flag}/SET_PASSWORD/verify-link`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            setErrorMessage(
              data.error || "Verification failed. Please try again."
            );
            setTimeout(() => {
              navigate({
                pathname: `/camp`,
                search: new URLSearchParams(window.location.search).toString(),
              });
            }, 2000);
          } else {
            const { token } = data;
            setUserToken(token);
          }
        })
        .catch((error) => {
          setErrorMessage("An error occurred. Please try again.");
        });
    }
  }, [flag, token, navigate]);

  const handleChangePassword = async (values: any) => {
    let payload: any = {
      newPassword: md5(values.password),
    };

    const response = await fetch(
      `${process.env.REACT_APP_WORKCAMP_API_URL}/api/user/reset-password`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token ?? userToken}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const data = await response.json();
    if (response.ok) {
      navigate({
        pathname: `/camp`,
        search: new URLSearchParams(window.location.search).toString(),
      });
    } else {
      setErrorMessage(data.error || "An error occurred. Please try again.");
    }
  };

  return (
    <Layout className="change-password">
      <Header />

      <Content className="content">
        <Typography.Title className="heading">Change Password</Typography.Title>
        <Typography.Paragraph className="subheading">
          Enter your new password here
        </Typography.Paragraph>
        <Form
          layout="vertical"
          name="change_password"
          className="form"
          initialValues={{ remember: true }}
          onFinish={handleChangePassword}
        >
          <Form.Item
            label="New Password"
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}
          >
            <Input.Password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="New Password"
            />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              { required: true, message: "Please confirm your Password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm Password" />
          </Form.Item>
          {errorMessage && (
            <Form.Item>
              <Typography.Text type="danger">{errorMessage}</Typography.Text>
            </Form.Item>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Change Password
            </Button>
          </Form.Item>
        </Form>
      </Content>
      <PageFooter />
    </Layout>
  );
};

export default NewPasswordPage;
