import { Footer } from "antd/es/layout/layout";

const PageFooter: React.FC = () => {
  return (
    <Footer
      style={{
        background: "transparent",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      All Rights Reserved &copy; 2019-2025 - TNG
    </Footer>
  );
};

export default PageFooter;
