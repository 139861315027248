import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ConfigProvider } from "antd";

import "./index.css";
import SignInPage from "./pages/SignIn";
import ForgotPasswordPage from "./pages/ForgotPassword";
import SignUpPage from "./pages/SignUp";
import VerificationPage from "./pages/Verification";
import CampRegistrationPage from "./pages/CampRegistration";
import AdminSignUpPage from "./pages/AdminSignUp";
import MainPage from "./pages/Main";
import NewPasswordPage from "./pages/NewPassword";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import CampPage from "./pages/Camp";
import LogoutPage from "./pages/Logout";

// Define routes in an array to avoid duplication
const routes = [
  { path: "/terms", element: <Terms /> },
  { path: "/privacy", element: <Privacy /> },
  { path: "/camp", element: <CampPage /> },
  { path: "/signin/:campName", element: <SignInPage /> },
  { path: "/forgot", element: <ForgotPasswordPage /> },
  { path: "/new-password/:token", element: <NewPasswordPage /> },
  { path: "/set-password/:flag", element: <NewPasswordPage /> },
  { path: "/signup/:campName", element: <SignUpPage /> },
  { path: "/verification/:email", element: <VerificationPage /> },
  { path: "/camp/registration/:campName", element: <CampRegistrationPage /> },
  { path: "/camp/:campId/admin-signup", element: <AdminSignUpPage /> },
  { path: "/logout", element: <LogoutPage /> },
  { path: "/", element: <MainPage /> },
];

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#3498DB",
          fontSize: 16, // Base font size
          fontFamily: "Product Sans",
          borderRadius: 8, // Global border radius
          colorBgLayout: "#f5f5f5", // Background color for layouts
        },
      }}
    >
      <Router>
        <Routes>
          {/* Normal Routes */}
          {routes.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}

          {/* Auth-prefixed Routes */}
          {routes.map(({ path, element }) => (
            <Route
              key={`/auth${path}`}
              path={`/auth${path}`}
              element={element}
            />
          ))}
        </Routes>
      </Router>
    </ConfigProvider>
  );
}

// Get the root container from the HTML document
const container = document.getElementById("root");

if (container) {
  const root = createRoot(container);
  root.render(<App />);
} else {
  console.error(
    "Root container not found. Make sure your HTML file has a <div id='root'></div>"
  );
}
