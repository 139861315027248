import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Button, Typography, Layout } from "antd";
import "./styles.scss";
import Header from "../components/header";
import { Content } from "antd/es/layout/layout";
import PageFooter from "../components/pageFooter";

const CampPage = () => {
  const [campName, setCampName] = useState("");
  const [campExists, setCampExists] = useState<boolean | null>(null);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (campName) {
      const timeout = setTimeout(async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_WORKCAMP_API_URL}/api/camp/check-camp-availability`,
            {
              method: "POST",
              headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ name: campName }),
            }
          );

          if (response.status === 200) {
            setCampExists(false);
          } else if (response.status === 409) {
            setCampExists(true);
          } else {
            setCampExists(null);
          }

          setProcessing(false);
        } catch (error) {
          console.error("Error checking camp availability:", error);
          setCampExists(null);
        }
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [campName]);

  const handleProceed = () => {
    if (campExists) {
      navigate({
        pathname: `/signin/${campName}`,
        search: new URLSearchParams(window.location.search).toString(),
      });
    } else {
      navigate({
        pathname: `/camp/registration/${campName}`,
        search: new URLSearchParams(window.location.search).toString(),
      });
    }
  };

  return (
    <Layout className="camp">
      <Header showExtras={true} />
      <Content className="content">
        <Typography.Title className="heading">Go to Camp</Typography.Title>
        <Typography.Paragraph className="subheading">
          Enter the name of your camp, or reserve a new one
        </Typography.Paragraph>

        <div className="form">
          <Input
            value={campName}
            onChange={(e) => {
              setProcessing(true);
              setCampName(e.target.value);
            }}
            placeholder="Camp Name"
            style={{ marginBottom: "20px" }}
          />

          <Button
            type="primary"
            onClick={handleProceed}
            disabled={campExists === null || processing}
          >
            {campExists ? "Join Camp" : "Reserve Camp"}
          </Button>
        </div>
      </Content>
      <PageFooter />
    </Layout>
  );
};

export default CampPage;
