import React from "react";
import { Typography, Layout } from "antd";
import Header from "../components/header";
import PageFooter from "../components/pageFooter";

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const Privacy: React.FC = () => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header />
      <Content style={{ padding: "50px" }}>
        <Typography>
          <Title>Privacy Policy</Title>

          <Paragraph>
            Welcome to Workcamp, a product provided by Workcamp.inc. We are
            committed to protecting your privacy and safeguarding your personal
            information. This Privacy Policy explains how we collect, use,
            disclose, and protect your data when you use our services, which are
            accessible via mobile and web platforms.
          </Paragraph>

          <Title level={4}>Information We Collect</Title>
          <Paragraph>
            A. Information You Provide: We may collect the following types of
            information that you provide to us voluntarily. User Account
            Information: When you create an account, we may collect your name,
            email address, and other contact details. B. Automatically Collected
            Information: We may collect certain information automatically
            including Device Information: Information about your device when
            accessing our Product, including device type, operating system, and
            unique device identifiers. Usage Information: Details about your
            interactions with the Product, such as the features you use, content
            you view, and actions you take.
          </Paragraph>

          <Title level={4}>How We Use Your Information</Title>
          <Paragraph>
            We use the information we collect for the following purposes: A. To
            provide and improve our services. B.To personalize your experience.
            C. To communicate with you, respond to your inquiries, and provide
            customer support. D. To send you updates, promotions, and other
            relevant information about our services. E. To monitor and analyze
            usage patterns and trends.
          </Paragraph>

          <Title level={4}>Disclosure of Your Information</Title>
          <Paragraph>
            We may share your information with the following parties: SERVICE
            PROVIDERS: Third-party service providers who help us operate and
            improve our services. LEGAL REQUIREMENTS: When required by law or in
            response to legal processes. BUSINESS TRANSFERS: In connection with
            the sale, merger, or transfer of all or part of our business.
          </Paragraph>

          <Title level={4}>Data Security</Title>
          <Paragraph>
            We implement reasonable security measures to protect your data from
            unauthorized access, disclosure, alteration, or destruction.
          </Paragraph>

          <Title level={4}>Changes to this Privacy Policy</Title>
          <Paragraph>
            We may update this Privacy Policy from time to time. We will notify
            you of any significant changes by posting the updated Privacy Policy
            within the Product.
          </Paragraph>

          <Title level={4}>Your Choices</Title>
          <Paragraph>
            You can manage your account and communication preferences within the
            Product. You may also contact us to exercise your rights under
            applicable data protection laws.
          </Paragraph>

          <Title level={4}>Contact Us</Title>
          <Paragraph>
            If you have questions or concerns about this Privacy Policy or our
            data practices, please contact us at info@workcamp.com
          </Paragraph>
        </Typography>
      </Content>
      <PageFooter />
    </Layout>
  );
};

export default Privacy;
